// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.rsweeney.np.isccloud.io';
const STAGE = 'rsweeney';

export const environment = {
  production: true, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_PUXmm6xnZ',
  NG_APP_COGNITO_CLIENT_ID: '53dlok10b4fvaig99crduo4am0',
  NG_APP_COGNITO_REGION: 'us-east-2',
  // DEV for SQLAAS
  //NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_P2lFNRZtG',
  //NG_APP_COGNITO_CLIENT_ID: '1go0li8rgl9o9lvff4u95oinp4',
  //NG_APP_COGNITO_REGION: 'us-east-2',
  //NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_P2lFNRZtG',
  //NG_APP_COGNITO_CLIENT_ID: '1go0li8rgl9o9lvff4u95oinp4',
  //NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  SQLAAS_URL: 'https://pm1z1c7rul.execute-api.us-east-1.amazonaws.com/rsweeney',
};
